import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import SEO from "../components/seo";
import SubscribeForm from '../components/SubscribeForm';
import EssentialLinks from '../components/EssentialLinks';

import '../components/layout.scss'

const triggerGA = (category, label) => {
  if (category, label) {
    trackCustomEvent({
      category: category,
      action: "Click",
      label: label
    })
  }
}

const FreePage = ({data}) => (
    <section className='links-section sky-poster'>
    <div className="sky-bg">
    <SEO title="Free Music" />
    <div className="container py-5">
      <div className="row justify-content-center w-100 mx-auto">
        <div className="col-12 col-md-6 text-center mb-4">
          {/* Leave link commented out for not, until homepage is re-done */}
          {/* <Link to='/'> */}
            <Img fluid={data.logo.childImageSharp.fluid} />
          {/* </Link> */}
        </div>
      </div>
      <div className="row w-100 justify-content-center mx-auto px-2">
        <div className="col-12 col-md-6 py-3 text-center" style={{backgroundColor: "rgba(255, 255, 255, 0.7)"}}>
          <p className='text-center mb-3'><strong>FREE MUSIC | FIRST COME FIRST SERVE</strong></p>
          <h3 className='color-change mb-3'>solotape - Days On End (pt. I)</h3>          
          <p className='text-center mb-3'><strong>REDEEM:</strong> <a href="https://silvaelectronics.bandcamp.com/yum" target="_blank" style={{color: '#000000', backgroundColor: '#FFFF9D'}} onClick={() => triggerGA('free-page', 'bc-yum')} rel='noopener noreferrer'>https://silvaelectronics.bandcamp.com/yum</a></p>
          <hr />
          <p className='font-weight-bold'>t9av-7hjn</p> 
          <p className='font-weight-bold'>ttf6-vv8e</p>
          <p className='font-weight-bold'>6ubt-65jv</p>
          <p className='font-weight-bold'>uwv3-6ft8</p>
          <p className='font-weight-bold'>vpg5-kqun</p>
          <p className='font-weight-bold'>99uf-6zaj</p>
          <p className='font-weight-bold'>n3wm-yzuy</p>
          <p className='font-weight-bold'>5vj6-76jf</p>
          <p className='font-weight-bold'>g9gu-5k9a</p>
          <p className='font-weight-bold'>332d-7y67</p>
          <a href="/">Back</a>
          <hr />
          <p>If one of these Bandcamp codes don't work, try another one. If they are all used, then check back soon! We periodically update this page with fresh codes. We will post on our socials and send out an email when they are fresh, so follow be sure to follow us and join our newsletter.</p>
          <hr />
            <EssentialLinks gaCategory={'free-page'} />
            <hr />
            <p><strong>Important links:</strong> <a href='/links/' target='_blank' rel='noopener noreferrer' onClick={() => triggerGA('free-page', 'important-links')}>CLICK HERE</a></p>
            <hr />
            <h3 className="color-change text-center my-3 mb-0">Newsletter</h3>
            <SubscribeForm />
        </div>
      </div>
    </div>
    </div>      
  </section>
)

export const query = graphql`
  {
    logo: file(relativePath: {eq: "silva_electronics_logo-knife.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default FreePage
